:root {
  --primary: #000000;
  --secondary: #92a97c;
  --link-hover: #ccd5ae;
  --black: black;
  --bg: #fff;
  --white: #fff;
  --highlight: lightblue;
  --max-width: 1400px;
}

html * {
  color: var(--primary);
}

body {
  margin: 0 !important;
  font-family: "coolvetica", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

button {
  background-color: var(--black);
  border: none;
  color: var(--secondary);
  font-weight: 200;
  position: relative;
  transition: background-color 0.1s ease-in;

  &:hover {
    background-color: var(--highlight);
  }
}
