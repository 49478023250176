.About--container {
  width: 90%;
  margin: 4rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
}

.About--description {
  font-size: 0.8em;

  @media (max-width: 1200px) {
    font-size: 1em;
  }

}

.About--socials {
  letter-spacing: 0.1rem;
  font-size: 20px;
  margin: 0;
}

.About--image {
  padding: 2rem;
  img {
    width: 100%;
    object-fit: cover;
  }

}