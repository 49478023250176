.Card--container {
    --card-height: 500px;

    border-radius: 10px;
    margin: 0.2rem;
    position: relative;
    height: var(--card-height);
    background-color: black;
    transition: all 0.4s;

    &:hover {
        .Card--image--container {
            opacity: 0.4;
        }

        .Card--content--container {
            opacity: 1
        }

    }

    @media (max-width: 576px) {
        max-height: 200px;
        font-size: .6rem;

        .Card--header--container {
            font-size: 1rem;
        }

        .Card--image--container {
            opacity: 0.4;
        }

        .Card--content--container {
            opacity: 1
        }
    }
}

.Card--content--container {
    position: absolute;
    z-index: 1;
    opacity: 0;
    transition: all 0.2s;
    margin: 2rem;

    &>* {
        color: white;
    }
}

.Card--header--container {
    margin-top: 0.3rem;
    margin-bottom: 0.5rem;
}

.Card--image--container {
    height: var(--card-height);
    width: 100%;
    border-radius: 5px;
    position: absolute;
    opacity: 1;
    transition: all 0.2s;

    @media (max-width: 576px) {
        max-height: 200px;
    }
}

.Card--image--bounds {
    height: var(--card-height);
    overflow: hidden;
    border-radius: 5px;
    display: flex;
    align-items: center;

    @media (max-width: 576px) {
        max-height: 200px;
    }
}

.Card--image {
    width: 100%;
    /* Ensure the image fills the container width */
    height: 100%;
    /* Allow the image to expand to its original height */
    object-fit: cover;
}

.Card--link {
    color: var(--secondary);

    &__disabled {
        pointer-events: none;
    }

    &__hover {
        &:hover {
            text-decoration: underline;
        }
    }
}

.Card--video--container {
    width: 100%;
}

.Card--video--iframe {
    width: 100%;
    height: 300px;

    @media (max-width: 576px) {
        max-height: 200px;
    }
}