html {
  font-size: calc(15px + 0.390625vw);
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

p {
  font-weight: 300;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.2rem;
}

h5 {
  font-size: 1rem;
}

h6 {
  font-size: 0.8rem;
}

.bold {
  font-weight: 600;
}
.bolder {
  font-weight: 700;
}
.boldest {
  font-weight: 900;
}
.m0 {
  margin: 0;
}
.p0 {
  padding: 0;
}
.alt-text {
  color: var(--white);
}
a {
  &:hover {
    color: var(--link-hover);
  }
}
.link {
  &:hover {
    color: var(--link-hover);
  }
}

p {
  margin: 0;
}

.secondary {
  color: var(--secondary);
}

.title {
  font-weight: 200;
  letter-spacing: 0.5rem;
}
