.Resume--container {
  display: flex;
  justify-content: center;
  height: 100%;
}

.Resume--download {
  position: fixed;
  bottom: 50px;
  margin: auto;
  width: 80px;
  height: 80px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    scale: 1.1;
  }
}
