.App--container {
  width: 100%;
  padding: 4em 0;
  position: relative;

}

.App--title {
  text-align: center;
  letter-spacing: .8rem;
  font-weight: 200;
  margin-bottom: 3rem;
}

.App--grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem 2rem;
  width: 60vw;
  max-width: var(--max-width);
  margin: auto;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    width: 90%;
  }
}

.App--button {
  width: 100%;
  height: 2.5rem;
  padding: .4em;
}